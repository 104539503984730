import axios from "@/plugins/axios";

const urlPath = "/agency/api/v1/projects/";

const getProjects = (status = "") => {
  return axios.get(`${urlPath}?status=${status}`);
};

const createProject = (data) => {
  return axios.post(`${urlPath}`, data);
};

const updateProject = ({ id, data }) => {
  return axios.put(`${urlPath}${id}`, data);
};

const addMedia = (id, data) => {
  return axios.post(`${urlPath}${id}/attachments/`, data);
};

const removeFile = (projectId, fileId) => {
  return axios.delete(`${urlPath}${projectId}/attachments/${fileId}`);
};

const getProject = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const updateProjectStatus = ({ id, data }) => {
  return axios.post(`${urlPath}${id}/action`, data);
};

export default {
  getProjects,
  createProject,
  addMedia,
  getProject,
  removeFile,
  updateProject,
  updateProjectStatus,
};
